.App-header {
  font-family: 'Comfortaa', cursive;
  background-color: #EDFDF9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.dark-main {
  background-color: #2F4858;
}

.light {
  color: black;
}

.dark {
  color: white;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Comfortaa', cursive !important;
  word-wrap: break-word;
  white-space: normal;
}

.ui.grid>.row {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.ui.tiny.popup {
  font-family: 'Comfortaa', cursive;
}

.ui.message {
  margin: auto;
  max-width: 300px;
  margin-bottom: 10px;
}

p img {
  width: 80%;
  display: block;
  margin: auto;
}

ul li a {
  word-wrap: break-word;
  color: black;
}

ul li {
  margin: 10px 0;
}

pre code {
  border: 1px solid #ccc;
  border-radius: 10px;
}

p code {
  background-color: aliceblue;
  color: #557ADB;
}

.project-image {
  width: 90% !important;
  height: 75%;
  margin: auto;
  display: block;
}

.project-preview {
  width: 100% !important;
  height: 100vh;
  padding-top: 25px;
}

.blog-info {
  background-color: #E0E1E2 !important;
}

.project-info {
  text-align: left !important;
}

.project-button {
  margin-top: .25rem !important;
}

.media-button {
  -webkit-animation-name: bounce;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-name: bounce;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.skills-image {
  width: 80px;
  height: 80px;
}

.ui.segment.section-holder {
  background-color: #7cb0a3;
  width: 75%;
  text-align: center;
}

.skills-image {
  cursor: pointer;
}

.copyright {
  text-align: center;
  font-size: 12px;
  padding-bottom: 10px;
}

.project-info-row {
  height: 340px;
}

.site-link {
  margin-top: 25px !important;
}

.ui.card {
  text-align: center;
  padding: 2px;
  margin-left: auto;
  margin-right: auto;
}

.resume-pdf {
  height: 100vh;
  width: 100%;
}

.hidden {
  display: none;
}

.ui.button:hover {
  box-shadow: 0px 0px 8px 7px #ccc;
}

.ui.teal.buttons:hover {
  box-shadow: 0px 0px 8px 7px #ccc;
}

.ui.inverted.vertical.labeled.icon.ui.left.visible.sidebar.menu {
  width: 85px;
  -webkit-transition: width 1s;
  transition: width 1s;
}

.ui.inverted.vertical.labeled.icon.ui.left.visible.sidebar.menu:hover {
  width: 170px;
}

@-webkit-keyframes bounce {
  50% {-webkit-transform: translateY(-5px);}
}

@keyframes bounce {
  50% {transform: translateY(-5px);}
}

@media only screen and (max-width: 800px) {
  .project-info-row {
    height: inherit;
  }
  .ui.segment.section-holder {
    width: 50%;
  }
  .ui.inverted.vertical.labeled.icon.ui.left.visible.sidebar.menu {
    width: 90px;
  }
  .ui.inverted.vertical.labeled.icon.ui.left.visible.sidebar.menu:hover {
    width: 90px;
  }
  .preview-button {
    display: none !important;
  }
}
